import axios from "axios";
import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import {
  Card as SemiCard,
  Row as SemiRow,
  Col as SemiCol,
  Space,
  RadioGroup,
  Radio,
  Skeleton,
  Breadcrumb,
} from "@douyinfe/semi-ui";
import { IconSpin } from "@douyinfe/semi-icons";

import { myTitle, text, MyButton } from "../common";

import "./purchase.scss";

export default function PlanPurchase() {
  const [selectedBillingInterval, setSelectedBillingInterval] = React.useState(0);
  const [planDetail, setPlanDetail] = React.useState({});
  const [purchaseLoading, setPurchaseLoading] = React.useState(false);
  const [planLoading, setPlanLoading] = React.useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "planPurchase" });

  const clickBillingEntry = (v) => (e) => {
    setSelectedBillingInterval(v);
    console.log(v);
  };

  const fetchPlans = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/plans/" + id,
      method: "GET",
    }).then((r) => {
      if (r.data.code === 0) {
        setPlanDetail(r.data.data);
        setPlanLoading(false);
      } else {
        navigate("/plan");
      }
    });
  };

  const checkoutClicked = () => {
    setPurchaseLoading(true);
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/orders/create",
      method: "POST",
      data: {
        plan: planDetail.id,
        billing_interval: String(selectedBillingInterval),
      },
    }).then((r) => {
      setPurchaseLoading(false);
      window.open(r.data.data.payment_link, "_blank");
    });
  };

  React.useEffect(() => {
    fetchPlans();
  }, []);

  let total = "0.00";
  switch (selectedBillingInterval) {
    case 1:
      total = planDetail.monthly_price;
      break;
    case 3:
      total = planDetail.quarterly_price;
      break;
    case 6:
      total = planDetail.semiannually_price;
      break;
    case 12:
      total = planDetail.annually_price;
      break;
    default:
      total = "0.00";
  }

  const canCheckout = total > 0;

  // if (!planDetail) {
  //   return <Container fluid className="purchase-page"></Container>;
  // }

  console.log("planLoading is ", planLoading);
  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ marginTop: "25px", width: "150px" }}></Skeleton.Title>
      <Skeleton.Paragraph style={{ marginTop: "25px" }} rows={5}></Skeleton.Paragraph>
    </div>
  );

  return (
    <>
      <div className="grid">
        <SemiRow type="flex" justify="center">
          <SemiCol span={23}>
            <Skeleton loading={planLoading} placeholder={skeletonHolder}>
              <SemiRow style={{ marginTop: "25px" }} type="flex">
                {myTitle(5, 5, t("purchasePlan"))}
                <Breadcrumb style={{ marginLeft: "15px" }}>
                  <Breadcrumb.Item
                    onClick={() => {
                      navigate("/plan");
                    }}
                  >
                    {t("plans")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t("purchasePlan")}</Breadcrumb.Item>
                </Breadcrumb>
              </SemiRow>
              <SemiRow style={{ marginTop: "25px" }}>
                <SemiCard>
                  <SemiRow type="flex" gutter={16}>
                    <Space spacing={14} vertical align="start">
                      {text(5, 15, t("purchasing"))}
                      {myTitle(5, 6, planDetail.name)}
                    </Space>
                  </SemiRow>
                  <Space spacing={14}></Space>
                  <SemiRow type="flex" gutter={16}>
                    <Space spacing={16} align="start" vertical style={{ width: "100%" }}>
                      {text(5, 15, t("chooseInterval"))}
                      <SemiRow style={{ width: "100%" }} gutter={16} type="flex" justify="start">
                        <SemiCol xs={23} xl={6} md={12} style={{ marginTop: "16px" }}>
                          <SemiCard
                            style={
                              selectedBillingInterval == 1
                                ? { borderColor: "#727cf5" }
                                : {
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() => setSelectedBillingInterval(1)}
                          >
                            <Space spacing={15} vertical align="start">
                              {myTitle(5, 5, t("monthly"))}
                              {text(4, 15, t("price") + "$" + planDetail.monthly_price)}
                            </Space>
                          </SemiCard>
                        </SemiCol>
                        <SemiCol xs={23} xl={6} md={12} style={{ marginTop: "16px" }}>
                          <SemiCard
                            onClick={() => {
                              setSelectedBillingInterval(3);
                            }}
                            style={selectedBillingInterval == 3 ? { borderColor: "#727cf5" } : { cursor: "pointer" }}
                          >
                            <Space spacing={15} vertical align="start">
                              {myTitle(5, 5, t("quarterly"))}
                              {text(4, 15, t("price") + "$" + planDetail.monthly_price)}
                            </Space>
                          </SemiCard>
                        </SemiCol>
                        <SemiCol xs={23} xl={6} md={12} style={{ marginTop: "16px" }}>
                          <SemiCard
                            onClick={() => {
                              setSelectedBillingInterval(6);
                            }}
                            style={selectedBillingInterval == 6 ? { borderColor: "#727cf5" } : { cursor: "pointer" }}
                          >
                            <Space spacing={15} vertical align="start">
                              {myTitle(5, 5, t("biannual"))}
                              {text(4, 15, t("price") + "$" + planDetail.monthly_price)}
                            </Space>
                          </SemiCard>
                        </SemiCol>
                        <SemiCol xs={23} xl={6} md={12} style={{ marginTop: "16px" }}>
                          <SemiCard
                            onClick={() => {
                              setSelectedBillingInterval(12);
                            }}
                            style={selectedBillingInterval == 12 ? { borderColor: "#727cf5" } : { cursor: "pointer" }}
                          >
                            <Space spacing={15} vertical align="start">
                              {myTitle(5, 5, t("annual"))} {text(4, 15, t("price") + "$" + planDetail.monthly_price)}
                            </Space>
                          </SemiCard>
                        </SemiCol>
                      </SemiRow>
                      <SemiRow>
                        <Space vertical>
                          {myTitle(6, 4, t("total"))}
                          {text(5, 18, "$" + total)}
                        </Space>
                      </SemiRow>
                      <SemiRow>{text(5, 15, t("choosePaymentMethod"))}</SemiRow>
                      <SemiRow style={{ width: "100%" }} gutter={16} type="flex" justify="start">
                        <SemiCol xs={23} xl={6} md={12}>
                          <SemiCard style={{ borderColor: "#727cf5", cursor: "pointer" }}>
                            <img src="/alipay-logo.png" width="40" />{" "}
                            <span style={{ marginLeft: 10 }}>{t("alipay")}</span>
                          </SemiCard>
                        </SemiCol>
                      </SemiRow>
                      <SemiRow type="flex" justify="end" style={{ width: "100%" }}>
                        {purchaseLoading ? (
                          <MyButton disabled={true} btnSize="large">
                            <IconSpin spin />
                          </MyButton>
                        ) : (
                          <MyButton
                            btnColor="#727cf5"
                            btnSize="large"
                            disabled={!canCheckout}
                            onClick={checkoutClicked}
                          >
                            {t("checkout")}
                          </MyButton>
                        )}
                      </SemiRow>
                    </Space>
                  </SemiRow>
                </SemiCard>
              </SemiRow>
            </Skeleton>
          </SemiCol>
        </SemiRow>
      </div>
    </>
  );
}
