import axios from "axios";
import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { text } from "../common";

import { Layout, Col, Row, Card, Table, Typography, Skeleton } from "@douyinfe/semi-ui";

export default function AnnouncementIndex() {
  const { Title, Text } = Typography;
  const { t } = useTranslation("translation", { keyPrefix: "announcements" });
  const [loading, setLoading] = React.useState(true);

  const [announcements, setAnnouncements] = React.useState([]);

  const fetchAnnouncements = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/announcements",
      method: "GET",
    })
      .then((r) => {
        setAnnouncements(r.data.data);
        console.log(r.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const announcementEntires = announcements.map((x, i) => (
    <tr key={i}>
      <td>{x.content}</td>
      <td>{DateTime.fromISO(x.created_at).toFormat("LLLL d, yyyy")}</td>
    </tr>
  ));

  React.useEffect(() => {
    fetchAnnouncements();
  }, []);

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ marginTop: "25px", width: "150px" }}></Skeleton.Title>
      <Skeleton.Paragraph style={{ marginTop: "25px" }} rows={5}></Skeleton.Paragraph>
    </div>
  );

  return (
    <div className="grid">
      <Row type="flex" justify="center">
        <Col xs={23}>
          <Skeleton loading={loading} placeholder={skeletonHolder}>
            <Row style={{ marginTop: "25px" }}>
              <Title style={{ color: "rgba(var(--semi-grey-5), 1)" }} heading={5}>
                {t("announcements")}
              </Title>
            </Row>
            <Row style={{ marginTop: "25px" }}>
              <Card>
                <Table dataSource={announcements}>
                  <Table.Column
                    title={text(3, 15, t("content"))}
                    dataIndex="content"
                    key="content"
                    render={(v) => text(5, 15, v)}
                  />
                  <Table.Column
                    title={text(3, 15, t("date"))}
                    width={100}
                    dataIndex="created_at"
                    key="created_at"
                    render={(v) => text(5, 15, DateTime.fromISO(v).toFormat("LLLL d, yyyy"))}
                  />
                </Table>
              </Card>
            </Row>
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
}
