import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import Chart from "react-apexcharts";

import { useTranslation } from "react-i18next";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, userInfoAtom } from "../atom";

import ReactMarkdown from "react-markdown";
import IOSManualMD from "../markdown/ios-manual.md";
import AndroidManualMD from "../markdown/android-manual.md";
import WindowsManualMD from "../markdown/windows-manual.md";
import MacManualMD from "../markdown/mac-manual.md";

// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// import Button from "react-bootstrap/Button";

import { Row, Col, Card, Typography, Space, Tabs, TabPane, Button, Skeleton } from "@douyinfe/semi-ui";
import { IconCreditCard, IconCalendarClock, IconLayers } from "@douyinfe/semi-icons";

import { text } from "./common";

const { Title, Text } = Typography;

const BILLING_INTERVALS = {
  1: "monthly",
  3: "quarterly",
  6: "biannual",
  12: "annual",
};

export default function Dashboard() {
  const [productInfo, setProductInfo] = React.useState(null);
  const [manualTabIndex, setManualTabIndex] = React.useState(0);
  const [productAvailable, setProductAvailable] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [plans, setPlans] = React.useState([]);

  const [iOSManual, setiOSManual] = React.useState("");
  const [androidManual, setAndroidManual] = React.useState("");
  const [windowsManual, setWindowsManual] = React.useState("");
  const [macManual, setMacManual] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const [userInfo, setUserInfo] = useAtom(userInfoAtom);

  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });

  const fetchProductInfo = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/products/active",
        method: "GET",
      });

      if (res.data.code == 0) {
        let productInfo = res.data.data;
        console.log("productInfo is ", productInfo);
        setProductInfo(productInfo);
      } else {
        console.log(res.data);
        // await fetchProductAvailable();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchProductAvailable = async () => {
  //   try {
  //     const res = axios({
  //       url: process.env.REACT_APP_API_ROUTE + "/products/available",
  //       method: "GET",
  //     });
  //     if (res.data.code == 0) {
  //       let products = res.data.data;
  //       let availableProduct = products.filter((x) => x.status != -1);
  //       console.log("availableProduct is ", availableProduct);
  //       setProductAvailable(availableProduct);
  //     } else {
  //       console.log(res.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const fetchOrder = async () => {
  //   try {
  //     const res = await axios({
  //       url: process.env.REACT_APP_API_ROUTE + "/orders",
  //       method: "GET",
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const fetchPlan = async () => {
  //   try {
  //     let res = await axios({
  //       url: process.env.REACT_APP_API_ROUTE + "/plans",
  //       method: "GET",
  //     });

  //     if (res.data.code == 0) {
  //       let plans = res.data.data;
  //       console.log("plans is ", plans);
  //       setPlans(plans);
  //     } else {
  //       console.log(res.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const activateProduct = (id) => () => {
  //   axios({
  //     url: process.env.REACT_APP_API_ROUTE + "/products/activate",
  //     method: "POST",
  //     data: { id },
  //   }).then((r) => {
  //     fetchProductInfo();
  //   });
  // };

  const fetchManual = () => {
    fetch(IOSManualMD)
      .then((response) => response.text())
      .then((text) => {
        setiOSManual(text);
      });
    fetch(AndroidManualMD)
      .then((response) => response.text())
      .then((text) => {
        setAndroidManual(text);
      });
    fetch(WindowsManualMD)
      .then((response) => response.text())
      .then((text) => {
        setWindowsManual(text);
      });
    fetch(MacManualMD)
      .then((response) => response.text())
      .then((text) => {
        setMacManual(text);
      });
  };

  React.useEffect(() => {
    console.log("in useEffect");

    const fetchData = async () => {
      console.log("in async");

      // await fetchPlan();

      await fetchProductInfo();

      // await fetchOrder.catch((e) => console.log(e));

      setIsLoading(false);
    };
    fetchData();
    // fetchPlan();
    // fetchProductInfo();
    // fetchOrder();
    fetchManual();
  }, []);

  let dayLeft = "";
  const dataChart = {
    options: {
      chart: {
        id: "data-bar",
        toolbar: { show: false },
      },
      plotOptions: {
        enabled: true,
      },
    },
    series: [
      {
        name: t("remaining"),
        data: [],
      },
      {
        name: t("used"),
        data: [],
      },
    ],
  };
  if (productInfo?.expiry_date) {
    dayLeft = DateTime.fromFormat(productInfo.expiry_date, "yyyy-MM-dd").diffNow("day");
    dayLeft = Math.round(dayLeft.days);

    dataChart.series[0].data.push({
      x: t("remaining"),
      y: productInfo.traffic_remaining,
      fillColor: "#39AFD1",
    });
    dataChart.series[1].data.push({
      x: t("used"),
      y: productInfo.traffic_total - productInfo.traffic_remaining,
      fillColor: "#FA5C7C",
    });
  }

  if (!userInfo) {
    return null;
  }

  //   const readyToActivate = (

  //   );

  //   const producInfoExist

  //   const productAvailableCards = (

  //   );

  //   const oderProcessing =;

  //   const newUser = (

  //   );

  //没有待激活产品。
  let dashContent = (
    <Card>
      <Row>
        <Col sm={12}>
          <p>{t("noPlanTitle")}</p>
          <p>
            <Link to={"/plan"}>{t("goToShopLink")}</Link> {t("lookAtPlan")}
          </p>
        </Col>
      </Row>
    </Card>
  );

  // 有待激活产品 - 激活产品
  // if (productAvailable) {
  //   dashContent = (
  //     <Card className="widget-inline">
  //       <h5>{t("planAvailable")}</h5>
  //       <p>
  //         {t("level")} {productAvailable.level}
  //         <br />
  //         {t("plan")} {productAvailable.plan?.description}
  //         <br />
  //         {t("billingInterval")}{" "}
  //         {t(BILLING_INTERVALS[productAvailable.billing_interval])}
  //         <br />
  //         {t("totalData")} {productAvailable.traffic_total}
  //         <br />
  //       </p>
  //       <p>
  //         <Button
  //           theme="solid"
  //           size="large"
  //           //   type="tertiary"
  //           style={{
  //             backgroundColor: "#727cf5",
  //           }}
  //           onMouseEnter={(e) => {
  //             const btn = e.target;
  //             btn.style.backgroundColor = "rgba(var(--semi-indigo-4), 1)";
  //           }}
  //           onMouseLeave={(e) => {
  //             const btn = e.target;
  //             btn.style.backgroundColor = "#727cf5";
  //           }}
  //           onClick={activateProduct(productAvailable.id)}
  //         >
  //           {t("activateNow")}
  //         </Button>
  //       </p>
  //     </Card>
  //   );
  // }

  // 没有待激活产品 - 有订单存在 - 订单处理中
  // if (plans && productAvailable && productAvailable.length > 0) {
  //   dashContent = (
  //     <Card>
  //       <Row type="flex" gutter={16}>
  //         <Col xs={12} sm={8} md={8} lg={6}>
  //           <Card style={{ marginTop: 16 }} shadows="always">
  //             <h3 className="title">
  //               {productAvailable.id}: {productAvailable.plan.name}
  //             </h3>
  //             <p>
  //               {t("purchaseOn")} {productAvailable.create_date}
  //             </p>
  //             <p>
  //               {t("billingInterval")}{" "}
  //               {t(BILLING_INTERVALS[productAvailable.billing_interval])}
  //             </p>
  //             <ReactMarkdown children={productAvailable.description} />
  //             {/* <Link to={"/plan/purchase/" +  x.id} className="buy-plan-btn btn btn-primary">{t("fromPrice", {price: x.monthly_price})}</Link>*/}

  //             <Button
  //               theme="solid"
  //               size="large"
  //               //   type="tertiary"
  //               style={{
  //                 backgroundColor: "#727cf5",
  //               }}
  //               onClick={activateProduct(productAvailable.id)}
  //               onMouseEnter={(e) => {
  //                 const btn = e.target;
  //                 btn.style.backgroundColor = "rgba(var(--semi-indigo-4), 1)";
  //               }}
  //               onMouseLeave={(e) => {
  //                 const btn = e.target;
  //                 btn.style.backgroundColor = "#727cf5";
  //               }}
  //             >
  //               {t("activateNow")}
  //             </Button>
  //           </Card>
  //         </Col>
  //       </Row>
  //     </Card>
  //   );
  // }

  // // 没有待激活产品 - 没有订单（完全新用户）
  // if (productAvailable && productAvailable.length < 1) {
  //   dashContent = (
  //     <Card>
  //       <Row>
  //         <Col sm={12}>
  //           <p>{t("noPlanTitle")}</p>
  //           <p>
  //             <Link to={"/plan"}>{t("goToShopLink")}</Link> {t("lookAtPlan")}
  //           </p>
  //         </Col>
  //       </Row>
  //     </Card>
  //   );
  // }

  // 有可用产品 - dashboard正常渲染
  if (productInfo) {
    dashContent = (
      <Col>
        <Row style={{ marginTop: "25px" }}>
          <Card>
            <Row type="flex" justify="center" style={{ paddingBottom: "40px" }}>
              <Col xs={16} sm={8} md={8} lg={8} style={{ textAlign: "center" }}>
                <Space vertical spacing={30} style={{ marginTop: "40px" }}>
                  <IconLayers
                    style={{
                      fontSize: 25,
                      color: "rgba(var(--semi-grey-4), 1)",
                    }}
                  />
                  {text(6, 25, productInfo.level)}
                  {text(4, 15, t("serviceLevel"))}
                </Space>
              </Col>
              <Col xs={16} sm={8} md={8} lg={8} style={{ textAlign: "center" }}>
                <Space vertical spacing={30} style={{ marginTop: "40px" }}>
                  <IconCalendarClock
                    style={{
                      fontSize: 25,
                      color: "rgba(var(--semi-grey-4), 1)",
                    }}
                  />
                  {text(6, 25, dayLeft)}
                  {text(4, 15, t("daysLeft"))}
                </Space>
              </Col>
              <Col xs={16} sm={8} md={8} lg={8} style={{ textAlign: "center" }}>
                <Space vertical spacing={30} style={{ marginTop: "40px" }}>
                  <IconCreditCard
                    style={{
                      fontSize: 25,
                      color: "rgba(var(--semi-grey-4), 1)",
                    }}
                  />
                  {text(6, 25, DateTime.fromISO(productInfo.expiry_date).toFormat("LLLL d, yyyy"))}
                  {text(4, 15, t("expiryDate"))}
                </Space>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row style={{ marginTop: "15px" }}>
          <Card>
            <Space vertical align="start" spacing={15}>
              <Title heading={5} style={{ color: "rgba(var(--semi-grey-5), 1)" }}>
                {t("quickStart")}
              </Title>
              {text(4, 15, t("quickStartText"))}
              <Tabs type="line">
                <TabPane tab="iOS" itemKey="iOS" style={{ color: "rgba(var(--semi-grey-5), 1)" }}>
                  <ReactMarkdown children={iOSManual} style={{ color: "rgba(var(--semi-grey-5), 1)" }} />
                </TabPane>
                <TabPane tab="Android" itemKey="Android" style={{ color: "rgba(var(--semi-grey-5), 1)" }}>
                  <ReactMarkdown children={androidManual} />
                </TabPane>
                <TabPane tab="Windows" itemKey="Windows" style={{ color: "rgba(var(--semi-grey-5), 1)" }}>
                  <ReactMarkdown children={windowsManual} />
                </TabPane>
                <TabPane tab="OS X" itemKey="OS X" style={{ color: "rgba(var(--semi-grey-5), 1)" }}>
                  <ReactMarkdown children={macManual} />
                </TabPane>
              </Tabs>
            </Space>
          </Card>
        </Row>
      </Col>
    );
  }

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ width: 120, marginTop: 25 }} />

      <Skeleton.Paragraph style={{ width: "100%", height: 150, marginTop: 25 }} rows={4} />

      <Skeleton.Title style={{ width: 120, marginTop: 25 }} />
      <Skeleton.Paragraph style={{ width: "100%", height: 150, marginTop: 15 }} rows={4} />
    </div>
  );

  return (
    <div className="grid">
      <Row type="flex" justify="center">
        <Col span={23}>
          <Skeleton loading={isLoading} placeholder={skeletonHolder}>
            <Row style={{ marginTop: "25px" }}>
              <Title style={{ color: "rgba(var(--semi-grey-5), 1)" }} heading={5}>
                {t("welcomeUser")}
                {userInfo.email}
              </Title>
            </Row>
            <Row style={{ marginTop: "25px" }}>{dashContent}</Row>
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
}
