import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import UserUtil from "../../util/UserUtil";
import { useTranslation } from "react-i18next";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, userInfoAtom } from "../../atom";

import {
  Row,
  Col,
  Skeleton,
  Typography,
  Table,
  Card,
  Space,
  Form,
  InputGroup,
  Input,
  InputNumber,
} from "@douyinfe/semi-ui";

import { MyButton, myTitle, text } from "../common";

const { Label } = Form;
export default function UserSignIn() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("+86");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [code, setCode] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorType, setErrorType] = React.useState("");
  const [codeCd, setCodeCd] = React.useState(0);

  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useAtom(isLoginAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [userToken, setUserToken] = useAtom(jwtTokenAtom);

  const { t } = useTranslation("translation", { keyPrefix: "signIn" });

  const signin = () => {
    const cc = countryCode.substring(1);
    const phoneVerify = checkPhoneNo(cc + phoneNo);

    if (phoneVerify === false) {
      setErrorType("phoneError");
      setShowError(true);
      return;
    }
    console.log(phoneVerify, code);
    axios({
      url: process.env.REACT_APP_API_DOMAIN + "/api/v1/auth/login",
      method: "POST",
      data: {
        phone_no: phoneVerify,
        code,
      },
    })
      .then((r) => {
        console.log(r.data);

        if (r.data.code === 0) {
          console.log("in if of signin");
          //   let toastEl = document.getElementById("loginsuccesstoast");
          //   let toast = new bootstrap.Toast(toastEl); // eslint-disable-line
          //   toast.show();

          let token = r.headers["authorization"];
          setUserToken(token);
          setIsLogin(true);
          setUserInfo({ email: phoneNo }); // TODO: What should we set this to?
          axios.defaults.headers.common["Authorization"] = token;
          navigate("/dashboard");
        } else {
          setErrorType("signInError");
          setShowError(true);
        }
      })
      .catch((e) => {
        setErrorType("signInError");
        setShowError(true);
      });
  };

  const onInputKeydown = (e) => {
    setShowError(false);
    if (e.key == "Enter") {
      signin();
    }
  };

  const checkPhoneNo = (phone) => {
    // DOTO: how?

    // When phone number is valid
    return phone;

    // When invalid
    // return false;
  };

  const sendCode = () => {
    setShowError(false);
    const cc = countryCode.substring(1);
    const phoneVerify = checkPhoneNo(cc + phoneNo);
    if (phoneVerify === false) {
      setErrorType("phoneError");
      setShowError(true);
      return;
    }

    axios({
      url: process.env.REACT_APP_API_DOMAIN + "/api/v1/auth/verify/send",
      method: "POST",
      data: {
        phone_no: phoneVerify,
      },
    })
      .then((r) => {
        if (r.data.code === 0) {
          codeCountdown();
        } else {
          setErrorType("codeError");
          setShowError(true);
        }
      })
      .catch((e) => {
        setErrorType("codeError");
        setShowError(true);
      });
  };
  const codeCountdown = () => {
    setCodeCd(60);
    let codeInterval = setInterval(() => {
      setCodeCd((t) => {
        if (t - 1 <= 0) {
          clearInterval(codeInterval);
        }

        return t - 1;
      });
    }, 1000);
  };

  const countryCodeChange = (e) => {
    const newVal = e.target.value;
    console.log("in countryCodeChange:", newVal);
    if (/^\+\d*$/.test(newVal)) {
      setCountryCode(newVal);
    }
  };

  const countryCodeChange2 = (v) => {
    const newVal = v;
    console.log("in countryCodeChange2:", newVal);
    if (/^\+\d*$/.test(newVal)) {
      console.log("in if");
      setCountryCode(newVal);
    }
  };

  React.useEffect(() => {
    if (isLogin) {
      navigate("/dashboard");
    }
  });

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col xxl={7} xl={7} lg={7} md={15} xs={23}>
            <Row style={{ marginTop: "25px" }}>
              <Card
                header={
                  <div
                    style={{
                      height: "70px",
                      width: "100%",
                      backgroundColor: "#727cf5",
                    }}
                  ></div>
                }
                headerStyle={{ padding: "0px" }}
              >
                <Row type="flex" justify="center">
                  {myTitle(5, 4, t("signIn"))}
                </Row>
                <Space vertical style={{ width: "100%" }} align="start">
                  <Label>{t("phoneno")}</Label>
                  <InputGroup style={{ width: "100%" }}>
                    <Input
                      style={{ width: "20%" }}
                      value={countryCode}
                      onChange={countryCodeChange2}
                      onKeyDown={onInputKeydown}
                    />
                    <Input
                      placeholder={t("phoneno")}
                      style={{ width: "80%" }}
                      onInput={(e) => setPhoneNo(e.target.value)}
                      onKeyDown={onInputKeydown}
                    />
                  </InputGroup>
                  <Space></Space>
                  <Label>{t("code")}</Label>
                  <Input
                    placeholder={t("codePlaceholder")}
                    onChange={(v) => {
                      console.log(v);
                      setCode(v);
                    }}
                    suffix={
                      <MyButton btnColor="#67677a" onClick={sendCode} disabled={codeCd > 0} style={{ width: "100px" }}>
                        {t("sendCode")} {codeCd > 0 ? <span>({codeCd}s)</span> : null}
                      </MyButton>
                    }
                  ></Input>
                </Space>

                <Row type="flex" justify="center" style={{ marginTop: "25px" }}>
                  <MyButton btnColor="#727cf5" onClick={signin}>
                    {t("signIn")}
                  </MyButton>
                </Row>
                {showError ? (
                  <Row type="flex" justify="center" style={{ marginTop: "25px" }}>
                    <p style={{ color: "red" }}>{t(errorType)}</p>
                  </Row>
                ) : null}
              </Card>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: "15px" }}>
              {text(4, 15, t("noAccount"))} <Link to="/users/signup">{text(5, 15, t("signUp"))}</Link>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: "15px" }}>
              <Link to="/users/signin">{text(5, 12, t("signInWithPWD"))}</Link>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div>
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-4 col-lg-5">
                <div className="card">
                  <div className="card-header pt-4 pb-4 text-center bg-primary"></div>

                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <h4 className="text-dark-50 text-center pb-0 fw-bold">
                        {t("signIn")}
                      </h4>
                    </div>

                    <form>
                      <div className="mb-3">
                        <label htmlFor="phoneno" className="form-label">
                          {t("phoneno")}
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type="text"
                            id="countrycode"
                            required=""
                            style={{ flex: "0 0 18%" }}
                            value={countryCode}
                            onInput={countryCodeChange}
                            onKeyDown={onInputKeydown}
                          />
                          <input
                            className="form-control"
                            type="text"
                            id="phoneno"
                            required=""
                            placeholder={t("phoneno")}
                            value={phoneNo}
                            onInput={(e) => setPhoneNo(e.target.value)}
                            onKeyDown={onInputKeydown}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="code" className="form-label">
                          {t("code")}
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            id="code"
                            className="form-control"
                            placeholder={t("codePlaceholder")}
                            value={code}
                            onInput={(e) => setCode(e.target.value)}
                            onKeyDown={onInputKeydown}
                          />
                          <button
                            class="btn btn-secondary"
                            type="button"
                            onClick={sendCode}
                            disabled={codeCd > 0}
                          >
                            {t("sendCode")}
                            {codeCd > 0 ? <span>({codeCd}s)</span> : null}
                          </button>
                        </div>
                      </div>

                      {showError ? (
                        <div className="mb-3">
                          <p style={{ color: "red" }}>{t(errorType)}</p>
                        </div>
                      ) : null} */}

      {/* <div className="mb-3 mb-3">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="checkbox-signin" />
                                            <label className="form-check-label" htmlFor="checkbox-signin">Remember me</label>
                                        </div>
                                    </div> */}

      {/* <div className="mb-3 mb-0 text-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={signin}
                        >
                          {t("signIn")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-muted">
                      {t("noAccount")}{" "}
                      <Link to="/users/signup" className="text-muted ms-1">
                        <b>{t("signUp")}</b>
                      </Link>
                    </p>
                    <p>
                      <a href="#" className="text-muted">
                        <small>{t("forgetPassword")}</small>
                      </a>
                    </p>
                  </div>
                </div>

                <div
                  className="toast align-items-center bg-success text-white border-0"
                  id="loginsuccesstoast"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                  style={{ position: "fixed", bottom: 5, right: 10 }}
                >
                  <div className="d-flex">
                    <div className="toast-body">Sign in successfully!</div>
                    <button
                      type="button"
                      className="btn-close btn-close-white me-2 m-auto"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
