import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const screenWidth = window.innerWidth;

const screenSize = screenWidth > 768 ? "PC" : "mobile";

const SizeAtom = atom(screenSize);

export const screenSizeAtom = atom((get) => get(SizeAtom));

export const isLoginAtom = atom(false);
export const userInfoAtom = atomWithStorage(null);
export const jwtTokenAtom = atomWithStorage("auth_token", "");
