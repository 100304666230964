import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import ConfirmEmail from "./ConfirmEmail";

import {
  Row,
  Col,
  Skeleton,
  Typography,
  Table,
  Card,
  Space,
  Form,
  InputGroup,
  Input,
  InputNumber,
} from "@douyinfe/semi-ui";

import { MyButton, myTitle, text } from "../common";

const { Label } = Form;

export default function UserSignUp() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [afterReg, setAfterReg] = React.useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "signUp" });

  // Error: 1 - Confirm password incorrect, 2 - email taken, 3 - email require, 4 - password require
  const [error, setError] = React.useState([]);

  const signup = () => {
    setError([]);
    let singupErrors = [];

    if (!email) {
      singupErrors.push(3);
    }
    if (!password) {
      singupErrors.push(4);
    }
    if (singupErrors.length > 0) {
      setError(singupErrors);
      return;
    }

    if (password != confirmPassword) {
      singupErrors.push(1);
      setError(singupErrors);
      return;
    }

    axios({
      url: process.env.REACT_APP_API_DOMAIN + "/signup",
      method: "POST",
      data: {
        user: {
          email,
          password,
        },
      },
    })
      .then((r) => {
        // navigate("/");
        // After register
        setAfterReg(true);
      })
      .catch((e) => {
        let errorMsg = e.response.data.message.replace("User couldn't be created successfully.", "");
        errorMsg = errorMsg.trim();
        setError([2]);
      });
  };

  const onEmailInput = (e) => {
    setEmail(e.target.value);
    setError([]);
  };
  const onPasswordInput = (e) => {
    setPassword(e.target.value);
    setError([]);
  };
  const onConfirmPasswordInput = (e) => {
    setConfirmPassword(e.target.value);
    setError([]);
  };

  if (afterReg) {
    return <ConfirmEmail email={email} />;
  }

  const valid = (values) => {
    let err = {};
    if (values.email == undefined) {
      err.email = t("emailError");
    }
    if (!values.password) {
      err.password = t("passwordError");
    }
    if (values.terms == undefined) {
      err.terms = t("termsError");
    }
    if (values.password != values.confirmPassword) {
      err.confirmPassword = t("confirmPasswordError");
    }
    return err;
  };

  const submit = (values) => {
    const user = { email: values.email, password: values.password };
    axios({
      url: process.env.REACT_APP_API_DOMAIN + "/signup",
      method: "POST",
      data: {
        user: {
          email,
          password,
        },
      },
    })
      .then((r) => {
        // navigate("/");
        // After register
        setAfterReg(true);
      })
      .catch((e) => {
        let errorMsg = e.response.data.message.replace("User couldn't be created successfully.", "");
        errorMsg = errorMsg.trim();
        setError([2]);
      });
  };

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col xxl={7} xl={7} lg={7} md={15} xs={23}>
            <Row style={{ marginTop: "25px" }}>
              <Card
                header={
                  <div
                    style={{
                      height: "70px",
                      width: "100%",
                      backgroundColor: "#727cf5",
                    }}
                  ></div>
                }
                headerStyle={{ padding: "0px" }}
              >
                <Row type="flex" justify="center">
                  {myTitle(5, 4, t("signUp"))}
                </Row>
                <Form validateFields={valid} onSubmit={submit}>
                  <Form.Input
                    placeholder={t("emailPlaceholder")}
                    field="email"
                    label={text(5, 15, t("emailAddress"))}
                  ></Form.Input>
                  <Form.Input
                    placeholder={t("passwordPlaceholder")}
                    field="password"
                    mode="password"
                    label={text(5, 15, t("password"))}
                  ></Form.Input>
                  <Form.Input
                    placeholder={t("confirmPasswordPlaceholder")}
                    field="confirmPassword"
                    mode="password"
                    label={text(5, 15, t("confirmPassword"))}
                  ></Form.Input>

                  <Form.Checkbox label=" " field="terms" labelAlign="left">
                    {text(4, 15, t("terms"))}
                  </Form.Checkbox>

                  <Row type="flex" justify="center">
                    <MyButton btnColor="#727cf5" htmlType="submit">
                      {t("signUp")}
                    </MyButton>
                  </Row>
                </Form>
              </Card>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: "15px" }}>
              {t("haveAccount")} <Link to="/">{text(5, 15, t("SignIn"))}</Link>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary"></div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 fw-bold">{t("signUp")}</h4>
                  </div>
                  <form action="#">
                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        {t("emailAddress")}
                      </label>
                      <input
                        className={"form-control" + (error.includes(3) || error.includes(2) ? " is-invalid" : "")}
                        type="email"
                        id="emailaddress"
                        required
                        placeholder={t("emailPlaceholder")}
                        value={email}
                        onInput={onEmailInput}
                      />
                      <div className="invalid-feedback">{t(error.includes(2) ? "emailTaken" : "emailError")}</div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="password" className="form-label has-validation">
                        {t("password")}
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          id="password"
                          className={"form-control" + (error.includes(4) ? " is-invalid" : "")}
                          placeholder={t("passwordPlaceholder")}
                          value={password}
                          onInput={onPasswordInput}
                        /> */}
      {/* <div className="input-group-text" data-password="false">
                                                <span className="password-eye"></span>
                                            </div> */}
      {/* <div className="invalid-feedback">{t("passwordError")}</div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        {t("confirmPassword")}
                      </label>
                      <div className="input-group input-group-merge has-validation">
                        <input
                          type="password"
                          id="password-com"
                          className={"form-control" + (error.includes(1) ? " is-invalid" : "")}
                          placeholder={t("confirmPasswordPlaceholder")}
                          value={confirmPassword}
                          onInput={onConfirmPasswordInput}
                        />
                    
                        <div className="invalid-feedback">{t("confirmPasswordError")}</div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="checkbox-signup" />
                        <label className="form-check-label" htmlFor="checkbox-signup">
                          {t("terms")}
                        </label>
                      </div>
                    </div>

                    <div className="mb-3 text-center">
                      <button className="btn btn-primary" type="button" onClick={signup}>
                        {t("signUp")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    {t("haveAccount")}{" "}
                    <Link to="/" className="text-muted ms-1">
                      <b>{t("SignIn")}</b>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
