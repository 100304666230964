import axios from "axios";
import React, { useEffect } from "react";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";

import { Button } from "react-bootstrap";

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";

import {
  Card as SemiCard,
  Row as SemiRow,
  Col as SemiCol,
  Space,
  Skeleton,
  Table as SemiTable,
  Typography,
} from "@douyinfe/semi-ui";

import { myTitle, text, MyButton } from "../common";

const { Numeral } = Typography;

const columnHelper = createColumnHelper();

export default function OrderIndex() {
  const navigate = useNavigate();

  const [orderData, setOrderData] = React.useState([]);
  const [planData, setPlanData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [tableSort, setTableSort] = React.useState([]);
  const { t } = useTranslation("translation", { keyPrefix: "orderIndex" });

  const fetchProducts = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/orders",
        method: "GET",
      });
      // .then((r) => {
      //   setOrderData(r.data.data);
      // });
      if (res.data.code == 0) {
        setOrderData(res.data.data);
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchPlans = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/plans",
        method: "GET",
      });
      // .then((r) => {
      //   setPlanData(r.data.data);
      // });
      if (res.data.code == 0) {
        setPlanData(res.data.data);
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const orderTableColumn = [
    columnHelper.accessor("create_date", {
      header: t("date"),
    }),
    columnHelper.accessor("plan", {
      header: t("plan"),
      cell: (info) => {
        const plan = planData.find((x) => x.id == info.getValue());
        if (plan) {
          const planName = plan.name;
          return <span>{planName}</span>;
        }
      },
    }),
    columnHelper.accessor("price", {
      header: t("price"),
      cell: (info) => "$" + info.getValue(),
    }),
    columnHelper.accessor("product", {
      header: t("type"),
      cell: (info) => {
        const typeText = info.getValue() ? t("renew") : t("initPurchase");
        return <span>{typeText}</span>;
      },
    }),
    columnHelper.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const statusText = info.getValue() === 1 ? t("completed") : t("pending");
        return <span>{statusText}</span>;
      },
    }),
    columnHelper.accessor("id", {
      header: t("options"),
      cell: (id) => {
        const order_id = id.getValue();
        return (
          <Link className="btn btn-secondary btn-sm" to={"/order/" + order_id}>
            {t("detail")}
          </Link>
        );
      },
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    columns: orderTableColumn,
    data: orderData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: tableSort,
    },
    onSortingChange: setTableSort,
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const getData = async () => {
      const products = await fetchProducts();

      const plans = await fetchPlans();

      const tableData = products.map((e) => {
        const planID = e.plan;
        const plan = plans.find((planItem) => planItem.id === planID);
        if (plan) {
          Reflect.set(e, "planName", plan.name);
          return e;
        } else {
          Reflect.set(e, "planName", "");
          return e;
        }
      });

      console.log("tableData is ", tableData);
      setTableData(tableData);
      setIsLoading(false);
    };

    getData();

    // fetchProducts();
    // fetchPlans();
  }, []);

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ width: "150px", marginTop: "25px" }} />

      <Skeleton.Paragraph rows={5} style={{ width: "100%", marginTop: "25px" }} />
    </div>
  );
  return (
    <div>
      <div className="grid">
        <SemiRow type="flex" justify="center">
          <SemiCol span={23}>
            <Skeleton loading={isLoading} placeholder={skeletonHolder}>
              <SemiRow style={{ marginTop: "25px" }} type="flex" justify="space-between">
                {myTitle(5, 5, t("orders"))}
              </SemiRow>
              <SemiRow style={{ marginTop: "25px" }}>
                <SemiCard>
                  <SemiTable dataSource={tableData}>
                    <SemiTable.Column
                      title="创建时间"
                      dataIndex="created_at"
                      width={110}
                      render={(v) => DateTime.fromISO(v).toFormat("yyyy-mm-dd")}
                    />
                    <SemiTable.Column title="套餐" dataIndex="planName" width={60} />
                    <SemiTable.Column
                      title="价格"
                      dataIndex="price"
                      width={60}
                      render={(v) => (
                        <Numeral rule="numbers" precision={2}>
                          {v}
                        </Numeral>
                      )}
                    />
                    <SemiTable.Column
                      title="订单类型"
                      dataIndex="order_type"
                      width={60}
                      render={(v) => (!v ? t("renew") : t("initPurchase"))}
                    />
                    <SemiTable.Column
                      title="状态"
                      dataIndex="status"
                      width={80}
                      render={(v) => (v === 1 ? t("completed") : t("pending"))}
                    />
                    <SemiTable.Column
                      title="选项"
                      width={80}
                      render={(e) => (
                        <MyButton btnColor="#67677a" onClick={() => navigate("/order/" + e.id)}>
                          {t("detail")}
                        </MyButton>
                      )}
                    />
                  </SemiTable>
                </SemiCard>
              </SemiRow>
            </Skeleton>
          </SemiCol>
        </SemiRow>
      </div>
      {/* <Container fluid>
        <Row>
          <Col sm={12}>
            <div className="page-title-box">
              <h4 className="page-title">{t("orders")}</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Table className="dataTable dt-responsive mb-0">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id} className={(header.column.getCanSort() ? "sorting " : "") + ({ asc: "sorting_asc", desc: "sorting_desc" }[header.column.getIsSorted()] ?? "")} {...{ onClick: header.column.getToggleSortingHandler() }}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
}
