import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import {
  Row,
  Col,
  Skeleton,
  Typography,
  Table,
  Card,
  Space,
} from "@douyinfe/semi-ui";

import { text, myTitle, MyButton } from "../common";

const ticketStatus = ["waiting", "replied", "closed"];

export default function TicketIndex() {
  const navigate = useNavigate();

  const { t } = useTranslation("translation", { keyPrefix: "ticketIndex" });
  const [tickets, setTickets] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);

  const fetchTickets = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/tickets",
      method: "GET",
    })
      .then((r) => {
        if (r.data.code == 0) {
          setTickets(r.data.data);
          setisLoading(false);
        } else {
          console.log(r.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ticketEntires = tickets.map((x, i) => (
    <tr key={i}>
      <td>{x.title}</td>
      {/* <td>{DateTime.fromISO(x.created_at).toFormat("yyyy-mm-dd")}</td> */}
      <td>{DateTime.fromISO(x.created_at).toFormat("yyyy-LL-dd")}</td>
      <td>{t(ticketStatus[x.status])}</td>
      <td>
        <Link
          to={"/ticket/" + x.id}
          className="btn btn-secondary btn-sm float-end"
        >
          {t("view")}
        </Link>
      </td>
    </tr>
  ));

  React.useEffect(() => {
    fetchTickets();
  }, []);

  const skeletonHolder = (
    <div className="grid">
      <Row type="flex" justify="space-between" style={{ marginTop: "25px" }}>
        <Skeleton.Title style={{ width: 120 }}></Skeleton.Title>
        <Skeleton.Button></Skeleton.Button>
      </Row>
      <Row style={{ marginTop: "25px" }}>
        <Skeleton.Paragraph rows={5}></Skeleton.Paragraph>
      </Row>
    </div>
  );

  return (
    <>
      <div>
        <Row type="flex" justify="center">
          <Col span={23}>
            <Skeleton loading={isLoading} placeholder={skeletonHolder}>
              <Row
                style={{ marginTop: "25px" }}
                type="flex"
                justify="space-between"
              >
                {myTitle(5, 5, t("tickets"))}
                <MyButton
                  btnColor="#0acf97"
                  onClick={() => {
                    navigate("/ticket/create");
                  }}
                >
                  {t("newTicket")}
                </MyButton>
              </Row>
              <Row style={{ marginTop: "25px" }}>
                <Card>
                  <Table dataSource={tickets} rowKey="id">
                    <Table.Column
                      fixed
                      dataIndex="title"
                      key="title"
                      title={t("title")}
                      width={60}
                    />
                    <Table.Column
                      dataIndex="created_at"
                      key="created_at"
                      title={t("date")}
                      width={100}
                      render={(date) => {
                        console.log(date);
                        return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
                      }}
                    />
                    <Table.Column
                      width={80}
                      dataIndex="status"
                      key="status"
                      title={t("status")}
                      render={(v) => t(ticketStatus[v])}
                    />

                    <Table.Column
                      width={60}
                      key="view"
                      render={(v) => (
                        <MyButton
                          btnColor="#67677a"
                          onClick={() => {
                            navigate("/ticket/" + v.id);
                          }}
                        >
                          {t("view")}
                        </MyButton>
                      )}
                    />
                  </Table>
                </Card>
              </Row>
            </Skeleton>
          </Col>
        </Row>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Link to="/ticket/create" className="btn btn-success btn-sm">
                  {t("newTicket")}
                </Link>
              </div>
              <h4 className="page-title">{t("tickets")}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <table className="table table-centered mb-0">
                  <thead>
                    <tr>
                      <th>{t("title")}</th>
                      <th>{t("date")}</th>
                      <th>{t("status")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{ticketEntires}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
