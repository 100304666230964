import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

import "./index.scss";

import ReactMarkdown from "react-markdown";
import { Row, Skeleton, Col, Card, Space } from "@douyinfe/semi-ui";

import { MyButton, myTitle, text } from "../common";

const fakePlans = [
  {
    name: "fakePlan",
    level: 2,
    traffic: 100,
    monthly_price: 12.5,
    quarterly_price: 25.0,
    semiannually_price: 50.0,
    annually_price: 100.0,
    description: "黄金套餐",
    id: 1,
  },
  {
    name: "fakePlan",
    level: 2,
    traffic: 100,
    monthly_price: 12.5,
    quarterly_price: 25.0,
    semiannually_price: 50.0,
    annually_price: 100.0,
    description: "黄金套餐",
    id: 2,
  },
  {
    name: "fakePlan",
    level: 2,
    traffic: 100,
    monthly_price: 12.5,
    quarterly_price: 25.0,
    semiannually_price: 50.0,
    annually_price: 100.0,
    description: "黄金套餐",
    id: 3,
  },
  {
    name: "fakePlan",
    level: 2,
    traffic: 100,
    monthly_price: 12.5,
    quarterly_price: 25.0,
    semiannually_price: 50.0,
    annually_price: 100.0,
    description: "黄金套餐",
    id: 4,
  },
  {
    name: "fakePlan",
    level: 2,
    traffic: 100,
    monthly_price: 12.5,
    quarterly_price: 25.0,
    semiannually_price: 50.0,
    annually_price: 100.0,
    description: "黄金套餐",
    id: 8,
  },
];

export default function PlanIndex() {
  const navigate = useNavigate();
  const [plans, setPlans] = React.useState([]);
  const { t } = useTranslation("translation", { keyPrefix: "planIndex" });
  const [isLoading, setisloading] = React.useState(true);

  const fetchPlans = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/plans",
      method: "GET",
    }).then((r) => {
      console.log("plans is ", r.data);
      if (r.data.data.length == 0) {
        setPlans(fakePlans);
      } else {
        setPlans(r.data.data);
      }
      setisloading(false);
    });
  };

  React.useEffect(() => {
    fetchPlans();
  }, []);

  const planCards = plans.map((x) => (
    <Col xs={23} sm={12} md={8} lg={6} xl={6} key={x.id}>
      <Card
        style={{ marginBottom: "16px" }}
        footerStyle={{ padding: "0px" }}
        footer={
          <MyButton block={true} btnColor="#727cf5" onClick={() => navigate("/plan/purchase/" + x.id)}>
            {t("fromPrice", { price: x.monthly_price })}
          </MyButton>
        }
        shadows="always"
      >
        <div style={{ textAlign: "center" }}>
          <Space vertical spacing={20}>
            {myTitle(5, 3, x.name)}
            {text(4, 14, <ReactMarkdown children={x.description} />)}
          </Space>
        </div>
      </Card>
    </Col>
  ));

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ marginTop: "25px", width: "150px" }}></Skeleton.Title>
      <Skeleton.Paragraph style={{ marginTop: "25px" }} rows={5}></Skeleton.Paragraph>
    </div>
  );

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col span={23}>
            <Skeleton loading={isLoading} placeholder={skeletonHolder}>
              <Row style={{ marginTop: "25px" }} type="flex" justify="space-between">
                {myTitle(5, 5, t("plans"))}
              </Row>
              <Row style={{ marginTop: "25px" }}>
                <Card>
                  <Row type="flex" gutter={16}>
                    {planCards}
                  </Row>
                </Card>
              </Row>
            </Skeleton>
          </Col>
        </Row>
      </div>
    </>
  );
}
