import React from "react";

import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
// import { useSelector, useDispatch } from 'react-redux'
import UserUtil from "./util/UserUtil";
// import { setIsLoginTrue, setIsLoginFalse, setUserInfo } from './store';
import axios from "axios";

import UserSignIn from "./views/users/SignIn";
import UserSignUp from "./views/users/SignUp";
import Dashboard from "./views/Dashboard";
import AnnouncementIndex from "./views/announcements/Index.js"; // index.js ?
import TicketIndex from "./views/ticket/Index";
import TicketCreate from "./views/ticket/Create";
import TicketDetail from "./views/ticket/Detail";
import PlanIndex from "./views/plan/Index";
import PlanPurchase from "./views/plan/purchase";
import PlanPurchaseSuccess from "./views/plan/success";
import OrderIndex from "./views/order/Index";
import OrderDetail from "./views/order/Detail";
import UserSettings from "./views/users/Settings";
// import Dropdown from "react-bootstrap/Dropdown";
import ConfirmEmail from "./views/users/ConfirmEmail";
import Confirmation from "./views/users/Confirmation";
import ProductIndex from "./views/products";

import { Nav, Icon, SideSheet, Layout, Avatar, Dropdown } from "@douyinfe/semi-ui";
import {
  IconComment,
  IconStar,
  IconSetting,
  IconSemiLogo,
  IconUser,
  IconEdit,
  IconLanguage,
  IconExit,
  IconMenu,
} from "@douyinfe/semi-icons";
import { useTranslation } from "react-i18next";

import "./App.css";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, userInfoAtom, screenSizeAtom } from "./atom";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

console.log(window.innerHeight);

function App() {
  const [isLogin, setIsLogin] = useAtom(isLoginAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [userToken, setUserToken] = useAtom(jwtTokenAtom);
  const [screenSize] = useAtom(screenSizeAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [navVisible, setNavVisible] = React.useState(false);
  const [maskVisible, setmaskVisible] = React.useState(false);

  const path = location.pathname.split("/")[1];

  // console.log(location.pathname.split("/"));

  const fetchUserInfo = () => {
    return axios({
      url: process.env.REACT_APP_API_DOMAIN + "/current_user",
      method: "GET",
    })
      .then((r) => {
        setUserInfo(r.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          logout();
        }
      });
  };

  const logout = () => {
    setUserToken("");
    setIsLogin(false);
    setUserInfo(null);
    delete axios.defaults.headers.common["Authorization"];
    navigate("/");
  };

  const changeLanguage = () => {
    if (i18n.language == "en-CA") {
      i18n.changeLanguage("zh-CN");
    } else if (i18n.language == "zh-CN") {
      i18n.changeLanguage("en-CA");
    }
  };

  useEffect(() => {
    if (userToken) {
      // fetchUserInfo();
      setIsLogin(true);
      axios.defaults.headers.common["Authorization"] = userToken;
    } else {
      const excludeRedirect = ["/users/signup", "/users/confirmation"];
      if (!excludeRedirect.includes(location.pathname)) {
        navigate("/");
      }
    }
  }, []);

  const navItems = [
    {
      itemKey: "dashboard",
      text: t("navigation.dashboard"),
      icon: <IconStar />,
    },
    {
      itemKey: "announcement",
      text: t("navigation.announcements"),
      icon: <IconComment />,
    },

    {
      text: t("navigation.tickets"),
      icon: <IconSetting />,
      itemKey: "ticket",
    },
    {
      text: t("navigation.plans"),
      icon: <IconSetting />,
      itemKey: "plan",
    },
    {
      text: t("navigation.orders"),
      icon: <IconSetting />,
      itemKey: "order",
    },
    {
      text: t("navigation.products"),
      icon: <IconSetting />,
      itemKey: "product",
    },
    // {
    //   text: t("topBar.logout"),
    //   icon: <IconSetting />,
    //   onClick: () => {},
    // },
  ];

  const navbar = (
    <Nav
      selectedKeys={[path]}
      style={screenSize === "PC" ? { position: "fixed", zIndex: 15, height: "100vh" } : { width: "100%" }}
      header={
        screenSize === "PC"
          ? {
              logo: <IconSemiLogo size={"extra-large"} />,
              text: t("navigation.navigation"),
            }
          : null
      }
      items={navItems}
      onSelect={(data) => {
        console.log("trigger onSelect: ", data);
        console.log("slected item is: ", path);
        // setselectedKey([data.itemKey]);
        navigate("/" + data.itemKey);
        setNavVisible(false);
      }}
    />
  );

  return (
    <Layout className="components-layout-demo">
      <Layout.Sider
        style={{
          display: screenSize === "mobile" || isLogin === false ? "none" : "",
        }}
      >
        <div
          style={{
            width: "250px",
          }}
        >
          {isLogin ? navbar : null}
        </div>
      </Layout.Sider>

      <Layout>
        <Layout.Header style={{ height: "60px", zIndex: 5 }}>
          {userInfo && isLogin ? (
            <div
              style={{
                position: "fixed",
                width: "100vw",
                height: "60px",
                left: "0px",
              }}
            >
              <Nav
                mode={"horizontal"}
                style={{ height: "100%" }}
                items={[]}
                onSelect={(key) => console.log(key)}
                header={
                  screenSize === "mobile" ? (
                    <IconMenu
                      size="large"
                      onClick={() => {
                        const visible = navVisible;
                        if (!navVisible) {
                          setmaskVisible(true);
                        }
                        console.log("click menu");
                        setNavVisible(!navVisible);
                      }}
                    />
                  ) : null
                }
                footer={
                  <Dropdown
                    position="bottomRight"
                    render={
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<IconSetting />}
                          onClick={() => {
                            navigate("/user/settings");
                          }}
                        >
                          {t("topBar.settings")}
                        </Dropdown.Item>
                        <Dropdown.Item icon={<IconLanguage />} onClick={changeLanguage}>
                          {t("topBar.switchLanguage")}
                        </Dropdown.Item>
                        <Dropdown.Item icon={<IconExit />} onClick={logout}>
                          {t("topBar.logout")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    }
                  >
                    <Avatar size="small" color="indigo" style={{ margin: 4 }}>
                      <IconSetting />
                    </Avatar>
                  </Dropdown>
                }
              />
            </div>
          ) : null}
        </Layout.Header>

        <Layout.Content>
          <div
            style={{
              position: "fixed",
              width: "100%",
              zIndex: 5,
            }}
          >
            <div
              className="sidesheet-container"
              style={{
                height: maskVisible ? "100vh" : "0px",
                backgroundColor: "rgba(0,0,0,0)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <SideSheet
                headerStyle={{ padding: "0px", paddingBottom: "10px" }}
                afterVisibleChange={(isVisble) => {
                  if (!isVisble) {
                    setmaskVisible(false);
                  }
                }}
                title={navbar}
                visible={navVisible}
                closable={false}
                height="1"
                onCancel={() => setNavVisible(false)}
                placement="top"
                getPopupContainer={() => {
                  return document.querySelector(".sidesheet-container");
                }}
              ></SideSheet>
            </div>
          </div>
          <Routes>
            <Route path="/users/signup" element={<UserSignUp />} />
            <Route path="/users/confirmation" element={<Confirmation />} />
            <Route path="/" element={<UserSignIn />} />
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/announcement" element={<AnnouncementIndex />} />

            <Route path="/ticket" element={<TicketIndex />} />
            <Route path="/ticket/create" element={<TicketCreate />} />
            <Route path="/ticket/:id" element={<TicketDetail />} />

            <Route path="/plan" element={<PlanIndex />} />
            <Route path="/plan/purchase/:id" element={<PlanPurchase />} />
            <Route path="/plan/purchase/success" element={<PlanPurchaseSuccess />} />

            <Route path="/order" element={<OrderIndex />} />
            <Route path="/order/:id" element={<OrderDetail />} />

            <Route path="/product" element={<ProductIndex />} />

            <Route path="user/settings" element={<UserSettings />} />
          </Routes>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default App;
