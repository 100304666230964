import axios from "axios";
import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import { useAtom } from "jotai";
import { userInfoAtom } from "../../atom";

import {
  Card as SemiCard,
  Row as SemiRow,
  Col as SemiCol,
  Space,
  Skeleton,
  Breadcrumb,
  Table as SemiTable,
} from "@douyinfe/semi-ui";

import { myTitle, text, MyButton } from "../common";

import "./Detail.scss";

const billingInterval = {
  1: "monthly",
  3: "quarterly",
  6: "biannual",
  12: "annual",
};

export default function OrderDetail() {
  const { id } = useParams();
  const { t } = useTranslation("translation", { keyPrefix: "orderDetail" });

  const [orderDetail, setOrderDetail] = React.useState({});
  const [planDetail, setPlanDetail] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const [userInfo, setUserInfo] = useAtom(userInfoAtom);

  const navigate = useNavigate();

  const fetchOrderDetail = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/orders/" + id,
        method: "GET",
      });
      //   .then((r) => {
      //     let order = Object.assign({}, r.data.data);
      //     if (order.price) {
      //       order.price = Number(order.price).toFixed(2);
      //     }
      //     fetchPlans(order.plan);
      //     setOrderDetail(order);
      //   });
      if (res.data.code == 0) {
        const order = res.data.data;
        if (order.price) {
          order.price = Number(order.price).toFixed(2);
        }
        const plan = await fetchPlans(order.plan);

        if (plan.name) {
          Reflect.set(order, "planName", plan.name);
        } else {
          Reflect.set(order, "planName", "");
        }

        setOrderDetail(order);
        console.log(order);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPlans = async (planId) => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/plans/" + planId,
        method: "GET",
      });
      //   .then((r) => {
      //     setPlanDetail(r.data.data);
      //   });
      if (res.data.code == 0) {
        setPlanDetail(res.data.data);
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitPayment = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/orders/" + id + "/pay",
      method: "POST",
    }).then((r) => {
      console.log(r.data);
    });
  };

  const getStatus = (status) => {
    return status === 1 ? t("completed") : t("pending");
  };

  useEffect(() => {
    const getData = async () => {
      await fetchOrderDetail();

      setIsLoading(false);
    };
    // fetchOrderDetail();
    // fetchPlans();
    getData();
  }, []);

  // if (!orderDetail || !planDetail) {
  //   return <div></div>;
  // }

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ marginTop: "25px", width: "150px" }}></Skeleton.Title>
      <Skeleton.Paragraph style={{ marginTop: "25px" }} rows={5}></Skeleton.Paragraph>
    </div>
  );

  return (
    <>
      <div className="grid">
        <SemiRow type="flex" justify="center">
          <SemiCol span={23}>
            <Skeleton loading={isLoading} placeholder={skeletonHolder}>
              <SemiRow style={{ marginTop: "25px" }} type="flex" justify="start">
                {myTitle(5, 5, t("orderDetail"))}
                <Breadcrumb style={{ marginLeft: "15px" }}>
                  <Breadcrumb.Item onClick={() => navigate("/order")}>{t("orders")}</Breadcrumb.Item>
                  <Breadcrumb.Item>{t("orderDetail")}</Breadcrumb.Item>
                </Breadcrumb>
              </SemiRow>
              <SemiRow style={{ marginTop: "25px" }}>
                <SemiCard>
                  <SemiRow style={{ marginBottom: "30px" }} type="flex" justify="space-between">
                    <SemiCol>{myTitle(5, 5, t("hello") + userInfo.email)}</SemiCol>
                    <SemiCol>
                      <SemiRow style={{ marginBottom: "10px" }}>
                        {myTitle(
                          5,
                          6,
                          t("orderDate") + DateTime.fromISO(orderDetail.created_at).toFormat("yyyy-MM-dd")
                        )}
                      </SemiRow>
                      <SemiRow>{myTitle(5, 6, t("orderStatus") + getStatus(orderDetail.status))}</SemiRow>
                    </SemiCol>
                  </SemiRow>
                  <SemiRow style={{ marginBottom: "30px" }}>
                    <SemiTable dataSource={[orderDetail]} style={{ marginBottom: "10px" }} pagination={null}>
                      <SemiTable.Column
                        title={t("plan")}
                        dataIndex="plan"
                        width={200}
                        render={() =>
                          planDetail.name +
                          " - " +
                          planDetail.description +
                          " - " +
                          t(billingInterval[orderDetail.billing_interval])
                        }
                      />
                      <SemiTable.Column width={80} title={t("quantity")} render={() => "1"} />
                      <SemiTable.Column width={80} title={t("unitCost")} render={() => "$" + orderDetail.price} />
                      <SemiTable.Column width={80} title={t("total")} render={() => "$" + orderDetail.price} />
                    </SemiTable>
                  </SemiRow>
                  <SemiRow type="flex" justify="end">
                    {myTitle(6, 3, "$" + orderDetail.price + "CAD")}
                  </SemiRow>
                </SemiCard>
              </SemiRow>
            </Skeleton>
          </SemiCol>
        </SemiRow>
      </div>
      {/* <Container fluid>
        <Row>
          <Col sm={12}>
            <div className="page-title-box">
              <h4 className="page-title">{t("orderDetail")}</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <div className="order-entry">
                      <Row>
                        <Col sm={6}>
                          <h4>
                            {t("hello")} {userInfo.email}
                          </h4>
                        </Col>
                        <Col sm={{ span: 4, offset: 2 }}>
                          <div className="float-end">
                            <p>
                              <strong>{t("orderDate")}</strong> <span className="float-end detail-text">{orderDetail.create_date}</span>
                            </p>
                            <p>
                              <strong>{t("orderStatus")}</strong> <span className="float-end detail-text">{getStatus(orderDetail.status)}</span>
                            </p>
                          </div>
                        </Col>
                      </Row>
      <Row>For Iframe QR code</Row>
      <Row className="mt-3">
                        <Col>
                          <Table>
                            <thead>
                              <tr>
                                <th>{t("plan")}</th>
                                <th>{t("quantity")}</th>
                                <th>{t("unitCost")}</th>
                                <th className="text-end">{t("total")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {planDetail.description} - {t(billingInterval[orderDetail.billing_interval])}
                                </td>
                                <td>1</td>
                                <td>${orderDetail.price}</td>
                                <td className="text-end">${orderDetail.price}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 6, offset: 6 }}>
                          <div className="float-end">
                            <p>
                              <strong>{t("subtotal")}</strong> <span className="float-end detail-text">${orderDetail.price}</span>
                            </p>
                            <h3>${orderDetail.price} CAD</h3>
                          </div>
                        </Col>
                      </Row> */}
      {/*<Row className="mt-2">*/}
      {/*    <Col>*/}
      {/*        <div className="text-end">*/}
      {/*            <a href="#" className="btn btn-primary"><i className="mdi mdi-printer"></i> {t('print')}</a>*/}
      {/*        </div>*/}
      {/*    </Col>*/}
      {/*    <Col>*/}
      {/*        <div className="text-end">*/}
      {/*            <a href="#" className="btn btn-primary" onClick={submitPayment}><i className="mdi mdi-printer"></i> {t('pay')}</a>*/}
      {/*        </div>*/}
      {/*    </Col>*/}
      {/*</Row>*/}
      {/* </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}
