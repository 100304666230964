import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
import resources from './language/resources';

// language detection plugin
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en-CA", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // lng: i18n.options.lng,
    fallbackLng: "en-CA",
    supportedLngs: ["en-CA", "zh-CN"],

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
