import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Skeleton, Table } from "@douyinfe/semi-ui";
import { myTitle } from "../common";

const PRODUCT_STATUS = {
  0: "available",
  1: "active",
  "-1": "expired",
};

export default function ProductIndex() {
  const { t } = useTranslation("translation", { keyPrefix: "products" });

  const [products, setProducts] = React.useState([]);
  const [plans, setPlans] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);

  const fetchProducts = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/products",
        method: "GET",
      });
      //   .then((r) => {
      //     setProducts(r.data.data);
      //     console.log(r.data.data);
      //   });
      console.log("productRes:", res);
      if (res.data.code == 0) {
        console.log("products is", res.data.data);
        setProducts(res.data.data);
        return res.data.data;
      } else {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPlan = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API_ROUTE + "/plans",
        method: "GET",
      });
      //   .then((r) => {
      //     let plans = r.data.data;
      //     setPlans(plans);
      //   });
      console.log("planRes:", res);
      if (res.data.code == 0) {
        console.log("plans is", res.data.data);
        setPlans(res.data.data);
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const productEntires = products
    .filter((x) => !x.hidden)
    .map((x, i) => {
      let plan = plans.find((y) => y.id === x.plan);
      let planName = "";
      if (plan) {
        planName = plan.name;
      }
      return (
        <tr key={i}>
          <td>{x.id}</td>
          <td>{planName}</td>
          <td>{x.level}</td>
          <td>{x.price}</td>
          <td>{t(PRODUCT_STATUS[String(x.status)])}</td>
        </tr>
      );
    });

  React.useEffect(() => {
    const getData = async () => {
      const plan = await fetchPlan();
      const products = await fetchProducts();

      const tableData = products
        .filter((x) => !x.hidden)
        .map((x, i) => {
          let plan = plans.find((y) => y.id === x.plan);
          let planName = "";
          if (plan) {
            planName = plan.name;
          }
          Reflect.set(x, "planName", planName);
          return x;
        });

      console.log("table data is：", tableData);
      setTableData(tableData);
      setIsLoading(false);
    };
    getData();
    // fetchPlan();
    // fetchProducts();
  }, []);

  const skeletonHolder = (
    <div>
      <Skeleton.Title style={{ marginTop: "25px", width: "150px" }}></Skeleton.Title>
      <Skeleton.Paragraph rows={5} style={{ marginTop: "25px" }}></Skeleton.Paragraph>
    </div>
  );

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col span={23}>
            <Skeleton loading={isLoading} placeholder={skeletonHolder}>
              <Row style={{ marginTop: "25px" }} type="flex" justify="space-between">
                {myTitle(5, 5, t("productTitle"))}
              </Row>
              <Row style={{ marginTop: "25px" }}>
                <Card>
                  <Table dataSource={tableData} pagination={null}>
                    <Table.Column dataIndex="id" title="ID" width={60} />
                    <Table.Column dataIndex="planName" title={t("plan")} width={80} />
                    <Table.Column dataIndex="level" title={t("level")} width={60} />
                    <Table.Column dataIndex="price" title={t("price")} width={60} />
                    <Table.Column
                      dataIndex="status"
                      title={t("status")}
                      render={(v) => t(PRODUCT_STATUS[String(v)])}
                      width={80}
                    />
                    <Table.Column dataIndex="traffic_remaining" title={t("trafficRemaining")} width={80} />
                    <Table.Column dataIndex="expiry_date" title={t("expiryDate")} width={120} />
                  </Table>
                </Card>
              </Row>
            </Skeleton>
          </Col>
        </Row>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">产品列表</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <table className="table table-centered mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>计划</th>
                      <th>等级</th>
                      <th>价格</th>
                      <th>状态</th>
                    </tr>
                  </thead>
                  <tbody>{productEntires}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
