export default {
  "en-CA": {
    translation: {
      navigation: {
        dashboard: "Dashboard",
        announcements: "Announcements",
        tickets: "Tickets",
        plans: "Plans",
        orders: "Orders",
        products: "Products",
        navigation: "Navigation",
      },
      topBar: {
        switchLanguage: "切换语言",
        settings: "Settings",
        logout: "Logout",
      },
      signIn: {
        signIn: "Sign In",
        emailAddress: "Email Address",
        emailPlaceholder: "Enter your email",
        phoneno: "Phone Number",
        password: "Password",
        code: "Code",
        codePlaceholder: "Enter the code",
        sendCode: "Send code",
        passwordPlaceholder: "Enter your password",
        signInError: "Code or phone number is incorrect.",
        phoneError: "Phone number is invalid.",
        codeError: "Failed to send code",
        signUp: "Sign Up",
        noAccount: "Don't have an account?",
        forgetPassword: "Forgot your password?",
        signInWithPWD: "Sign in with password",
      },
      signUp: {
        signUp: "Sign Up",
        emailAddress: "Email Address",
        emailPlaceholder: "Enter your email",
        password: "Password",
        passwordPlaceholder: "Enter your password",
        emailError: "Email required",
        emailTaken: "Email Taken",
        passwordError: "Password required",
        confirmPassword: "Confirm Password",
        confirmPasswordPlaceholder: "Confirm your password",
        confirmPasswordError: "Password incorrect",
        terms: "I accept Terms and Conditions",
        termsError: "Please check Terms and Conditions",
        haveAccount: "Already have account?",
        SignIn: "Sign In",
      },
      dashboard: {
        welcomeUser: "Welcome! User",
        serviceLevel: "Service Level",
        expiryDate: "Expiry Date",
        daysLeft: "Days Left",
        myWallet: "My Wallet",
        dataUsage: "Data Usage",
        quickStart: "Quick Start",
        quickStartText: "Here is guide to quickly get started.",
        noPlanTitle: "Seems like you haven't purchased any plan yet.",
        goToShopLink: "Head to the shop",
        lookAtPlan: "and take a look at the plans",
        planAvailable: "You have a plan available",
        level: "Level:",
        billingInterval: "Billing Interval:",
        totalData: "Total Data:",
        activateNow: "Activate Now",
        remaining: "Remaining",
        used: "Used",
        plan: "Plan:",
        monthly: "Monthly",
        quarterly: "Quarterly",
        biannual: "Bi-Annually",
        annual: "Annually",
        pendingOrder: "You have pending orders",
        goToOrderLink: "Click here to check your orders.",
        purchaseOn: "Purchase on:",
      },
      announcements: {
        announcements: "Announcements",
        content: "Content",
        date: "Date",
      },
      ticketIndex: {
        tickets: "Tickets",
        newTicket: "New Ticket",
        title: "Title",
        date: "Date",
        view: "View",
        status: "Status",
        waiting: "Waiting",
        replied: "Replied",
        closed: "Closed",
      },
      ticketCreate: {
        tickets: "Tickets",
        newTicket: "New Ticket",
        title: "Title",
        content: "Content",
        submit: "Submit",
      },
      ticketDetail: {
        tickets: "Tickets",
        ticketDetail: "Ticket Detail",
        sender: "Sender",
        staff: "Staff",
        enterMessagePlaceholder: "Enter your reply",
        enterMessageError: "Please enter your messsage",
        reply: "Reply",
        close: "Close Ticket",
        ticketClosed: "Ticket is closed.",
      },
      planIndex: {
        plans: "Plans",
        name: "Name",
        data: "Data",
        monthly: "Monthly",
        quarterly: "Quarterly",
        biannual: "Bi-Annually",
        annual: "Annually",
        buy: "Buy",
        fromPrice: "From ${{price}}/Month",
      },
      planPurchase: {
        plans: "Plans",
        purchasePlan: "Purchase Plan",
        purchasing: "You are purchasing this plan:",
        chooseInterval: "Choose your billing interval:",
        monthly: "Monthly",
        quarterly: "Quarterly",
        biannual: "Bi-Annually",
        annual: "Annually",
        price: "Price:",
        total: "Total:",
        checkout: "Checkout",
        choosePaymentMethod: "Please select a payment method:",
        alipay: "Alipay",
      },
      orderIndex: {
        orders: "Orders",
        date: "Date",
        plan: "Plan",
        price: "Price",
        status: "Status",
        options: "Options",
        detail: "Detail",
        pending: "Pending",
        completed: "Completed",
      },
      orderDetail: {
        orders: "Orders",
        hello: "Hello,",
        orderDetail: "Order Detail",
        orderDate: "Order Date:",
        plan: "Plan",
        quantity: "Quantity",
        unitCost: "Unit Cost",
        orderStatus: "Order Status:",
        pending: "Pending",
        completed: "Completed",
        billingInterval: "Billing Interval:",
        monthly: "Monthly",
        quarterly: "Quarterly",
        biannual: "Bi-Annually",
        annual: "Annually",
        subtotal: "Subtotal:",
        print: "Print",
        total: "Total",
      },
      userSettings: {
        userSettings: "User Settings",
        passwords: "Passwords",
        password: "Password",
        confirmPassword: "Comfirm Password",
        submit: "Submit",
        passwordUpdated: "Password Updated!",
        passwordError: "Please enter a password.",
        confirmPasswordError: "Confirm password incorrect.",
      },
      planPurchaseSuccess: {
        purchasePlan: "Purchase Plan",
        purchaseSuccessful: "Purchase Successful!",
        viewOrder: "Click here to view orders",
      },
      confirmEmail: {
        checkEmail: "Please check your email",
        emailSent:
          "A email has been sent to <b>{{email}}</b>. Please check and click on the included link to confirm your email.",
        backToLogin: "Back to Login",
      },
      confirmation: {
        confirmSucess: "Email confirmed successfully",
        confirmSucessParagraph: "Your email has been confirmed.",
        confirmFail: "Email confirm failed",
        confirmFailParagraph: "We are not able to confirm your email at the moment.",
        backToLogin: "Back to Login",
        signInCountdown: "Directing to sign in page in {{cd}}",
      },
      products: {
        available: "Available",
        active: "Active",
        expired: "Expired",
        plan: "Plan",
        price: "Price",
        level: "Level",
        status: "Status",
        trafficRemaining: "Traffic Remaining",
        expiryDate: "Expiry Date",
        productTitle: "Products",
      },
    },
  },
  "zh-CN": {
    translation: {
      navigation: {
        dashboard: "用户面板",
        announcements: "公告",
        tickets: "工单",
        plans: "套餐",
        orders: "订单",
        navigation: "导航",
        products: "产品",
      },
      topBar: {
        switchLanguage: "Switch Language",
        settings: "设置",
        logout: "注销",
      },
      signIn: {
        signIn: "登录",
        emailAddress: "邮箱",
        emailPlaceholder: "请输入邮箱",
        password: "密码",
        passwordPlaceholder: "请输入密码",
        signInError: "邮箱或密码不正确",
        signUp: "注册",
        noAccount: "没有账号？",
        forgetPassword: "忘记密码？",
        signInWithPWD: "使用密码登陆",
      },
      signUp: {
        signUp: "注册",
        emailAddress: "邮箱",
        emailPlaceholder: "请输入邮箱",
        password: "密码",
        passwordPlaceholder: "请输入密码",
        emailError: "邮箱不能为空",
        emailTaken: "邮箱已被注册",
        passwordError: "密码不能为空",
        confirmPassword: "确认密码",
        confirmPasswordPlaceholder: "请再一次输入你的密码",
        confirmPasswordError: "密码不一致",
        terms: "我同意条款",
        termsError: "请勾选同意",
        haveAccount: "已有账号？",
        SignIn: "登录",
      },
      dashboard: {
        welcomeUser: "欢迎：",
        serviceLevel: "服务等级",
        daysLeft: "剩余天数",
        expiryDate: "到期时间",
        myWallet: "我的钱包",
        dataUsage: "剩余流量",
        quickStart: "快速开始",
        quickStartText: "这里是快速开始的教程",
        noPlanTitle: "你还没有购买任何套餐",
        goToShopLink: "前往商店",
        lookAtPlan: "浏览套餐",
        planAvailable: "你有可用的套餐",
        level: "等级：",
        billingInterval: "套餐时长:",
        totalData: "流量:",
        activateNow: "启用套餐",
        remaining: "剩余",
        used: "已用",
        plan: "套餐：",
        monthly: "月度",
        quarterly: "季度",
        biannual: "半年度",
        annual: "年度",
        pendingOrder: "你有处理中的订单",
        goToOrderLink: "点击此处查看订单",
        purchaseOn: "购买日期:",
      },
      announcements: {
        announcements: "网站公告",
        content: "内容",
        date: "日期",
      },
      ticketIndex: {
        tickets: "工单",
        newTicket: "新建工单",
        title: "标题",
        date: "日期",
        view: "查看",
        status: "状态",
        waiting: "待回复",
        replied: "已回复",
        closed: "关闭",
      },
      ticketCreate: {
        tickets: "工单",
        newTicket: "新建工单",
        title: "标题",
        content: "内容",
        submit: "提交",
      },
      ticketDetail: {
        tickets: "工单",
        ticketDetail: "工单详情",
        sender: "发送人",
        staff: "员工",
        enterMessagePlaceholder: "请输入回复",
        enterMessageError: "请输入回复信息",
        reply: "回复",
        close: "关闭工单",
        ticketClosed: "工单已关闭",
      },
      planIndex: {
        plans: "套餐",
        name: "名字",
        data: "流量",
        monthly: "月费",
        quarterly: "季度费",
        biannual: "半年费",
        annual: "年费",
        buy: "购买",
        fromPrice: "${{price}}/月 起",
      },
      planPurchase: {
        plans: "套餐",
        purchasePlan: "购买套餐",
        purchasing: "你即将购买：",
        chooseInterval: "请选择套餐时长：",
        monthly: "月度",
        quarterly: "季度",
        biannual: "半年度",
        annual: "年度",
        price: "价格:",
        total: "总计:",
        checkout: "结账",
        choosePaymentMethod: "请选择支付方式：",
        alipay: "支付宝",
      },
      orderIndex: {
        orders: "订单",
        date: "日期",
        plan: "套餐",
        price: "价格",
        status: "状态",
        options: "选项",
        detail: "查看详情",
        pending: "处理中",
        completed: "已完成",
        type: "订单类型",
        renew: "续费",
        initPurchase: "新购",
      },
      orderDetail: {
        orders: "订单",
        hello: "您好,",
        orderDetail: "订单详情",
        orderDate: "订单日期：",
        plan: "套餐",
        quantity: "数量",
        unitCost: "单价",
        orderStatus: "订单状态：",
        pending: "处理中",
        completed: "已完成",
        billingInterval: "套餐时长：",
        monthly: "月度",
        quarterly: "季度",
        biannual: "半年度",
        annual: "年度",
        subtotal: "小计:",
        print: "打印",
        pay: "支付",
        total: "总计",
      },
      userSettings: {
        userSettings: "用户设置",
        passwords: "密码",
        password: "密码",
        confirmPassword: "确认密码",
        submit: "提交",
        passwordUpdated: "密码更新成功！",
        passwordError: "请输入密码",
        confirmPasswordError: "密码不一致",
      },
      planPurchaseSuccess: {
        purchasePlan: "购买套餐",
        purchaseSuccessful: "购买成功！",
        viewOrder: "点击此处查看订单",
      },
      confirmEmail: {
        checkEmail: "请查看您的邮箱",
        emailSent: "我们已向<b>{{email}}</b>发送了一封邮件。请点击邮件里的链接以验证您的邮箱。",
        backToLogin: "回到登录",
      },
      confirmation: {
        confirmSucess: "邮箱验证成功",
        confirmSucessParagraph: "您的邮箱已验证成功",
        confirmFail: "邮箱验证失败",
        confirmFailParagraph: "暂时无法验证您的邮箱",
        backToLogin: "回到登录",
        signInCountdown: "{{cd}}秒后跳转到登录页面",
      },
      products: {
        available: "已暂停",
        active: "已激活",
        expired: "已过期",
        plan: "套餐",
        price: "价格",
        level: "等级",
        status: "状态",
        trafficRemaining: "剩余流量",
        expiryDate: "过期时间",
        productTitle: "产品列表",
      },
    },
  },
};
