import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

export default function Confirmation() {
    const {t} = useTranslation('translation', { keyPrefix: 'confirmation' });
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');
    const renderSuccess = (status == 'success');

    const navigate = useNavigate();

    const [cd, setCd] = React.useState(10);

    // 255, 91, 91 Red
    // 16, 196, 105 Green

    useEffect(() => {
        if (renderSuccess) {
            let cdInterval = setInterval(() => {
                setCd((x) => {
                    if (x <= 0) {
                        navigate('/');
                        return 0;
                    } else {
                        return x - 1;
                    }
                });
            }, 1000);

            return () => clearInterval(cdInterval);
        }
    }, []);

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <Container fluid>
            <Row className="justify-content-center">
                <Col xxl={4} lg={5}>
                    <Card>
                        <Card.Header className="pt-4 pb-4 text-center bg-primary"></Card.Header>
                        { renderSuccess ? 
                            <Card.Body>
                                <div className="text-center m-auto">
                                    <h4 className="text-dark-50 text-center mt-4 fw-bold">{t('confirmSucess')}</h4>
                                    <p className="text-muted mb-4">{t('confirmSucessParagraph')}</p>
                                </div>
                                <div class="mb-0 text-center">
                                    <p>{t('signInCountdown', {cd: cd})}</p>
                                </div>
                            </Card.Body> 
                            :
                            <Card.Body>
                                <div className="text-center m-auto">
                                    <h4 className="text-dark-50 text-center mt-4 fw-bold">{t('confirmFail')}</h4>
                                    <p className="text-muted mb-4">{t('confirmFailParagraph')}</p>
                                </div>
                                <div className="mb-0 text-center">
                                    <Link to="/"><Button variant="primary"><i className="mdi mdi-home me-1"></i> {t('backToLogin')}</Button></Link>
                                </div>
                            </Card.Body>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
    )
}