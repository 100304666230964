import axios from "axios";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, userInfoAtom } from "../../atom";

import { Row, Col, Card, Form, Space, Input, Typography, Breadcrumb } from "@douyinfe/semi-ui";
import { myTitle, MyButton } from "../common";
import { ChatBox } from "./uitil";

const { Text } = Typography;

export default function TicketDetail() {
  const [ticketDetail, setTicketDetail] = React.useState();
  const { id } = useParams();

  const [replyContent, setReplyContent] = React.useState("");
  const [replyInputError, setReplyInputError] = React.useState(false);

  const [ticketClosed, setTicketClosed] = React.useState(false);

  const [userInfo, setUserInfo] = useAtom(userInfoAtom);

  const { t } = useTranslation("translation", { keyPrefix: "ticketDetail" });

  const navigate = useNavigate();

  const fetchTicketDetail = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/tickets/" + id,
      method: "GET",
    }).then((r) => {
      console.log(r.data.data);
      setTicketDetail(r.data.data.ticket);
      setTicketClosed(r.data.data.ticket.status === 2);
    });
  };

  const submit = (e) => {};

  const submitReply = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!replyContent || replyContent.trim() == "") {
      setReplyInputError(true);
      return;
    }

    axios({
      url: process.env.REACT_APP_API_ROUTE + "/tickets/" + ticketDetail.id + "/reply",
      method: "POST",
      data: {
        content: replyContent,
      },
    }).then((r) => {
      fetchTicketDetail();
      setReplyContent("");
    });
  };

  const closeTicket = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/tickets/" + ticketDetail.id + "/close",
      method: "POST",
    }).then((r) => {
      fetchTicketDetail();
      setReplyContent("");
    });
  };

  const replyContentChange = (e) => {
    setReplyContent(e.target.value);
    setReplyInputError(false);
  };
  const replyInputKeydown = (e) => {
    if (e.code == "Enter") {
      submitReply();
    }
  };

  React.useEffect(() => {
    fetchTicketDetail();
  }, []);

  if (!ticketDetail) {
    return <div className="container-fluid"></div>;
  }

  const ticketChatObject = JSON.parse(ticketDetail.content);
  const ticketChatContent = ticketChatObject.map((x, i) => {
    return (
      <Row type="flex" justify="end" style={{ marginTop: "10px" }}>
        <ChatBox className={x.sender == 0 ? "chatBox-right" : ""}>
          <i>{x.sender == 0 ? userInfo.email : t("staff")}</i>
          <p>{x.content}</p>
        </ChatBox>
      </Row>
    );
  });

  const syncValidate = (values) => {
    const errors = {};
    if (values.reply !== "mike") {
      errors.name = "you must name mike";
    }
    errors.familyName = [
      { before: "before errror balabala ", after: "after error balabala" },
      "familyName[1] error balabala",
    ];
    return errors;
  };

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col span={23}>
            <Row style={{ marginTop: "25px" }} type="flex" gutter={16} justify="space-between">
              <Space>
                {myTitle(5, 5, t("ticketDetail"))}

                <Breadcrumb style={{ marginLeft: "15px" }}>
                  <Breadcrumb.Item
                    onClick={() => {
                      navigate("/ticket");
                    }}
                  >
                    {t("tickets")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t("ticketDetail")}</Breadcrumb.Item>
                </Breadcrumb>
              </Space>

              <MyButton btnColor="#fa5c7c" onClick={closeTicket} disabled={ticketClosed}>
                {t("close")}
              </MyButton>
            </Row>
            <Row style={{ marginTop: "25px" }}>
              {myTitle(5, 4, ticketDetail.title)}
              <Card>
                <SimpleBar style={{ height: 420 }}>
                  <ul className="conversation-list px-3">{ticketChatContent}</ul>
                </SimpleBar>
                <Row>
                  <Card
                    shadows="always"
                    style={{
                      backgroundColor: "eef2f7",
                    }}
                  >
                    <Input
                      aria-labelledby={"ticket-reply"}
                      disabled={ticketClosed}
                      placeholder={ticketClosed ? t("ticketClosed") : t("enterMessagePlaceholder")}
                      onChange={(v) => {
                        setReplyContent(v);
                        setReplyInputError(false);
                      }}
                      suffix={
                        <MyButton btnColor="#0acf97" htmlType="submit" onClick={submitReply} disabled={ticketClosed}>
                          {t("reply")}
                        </MyButton>
                      }
                    ></Input>
                    <Row type="flex" justify="center">
                      {replyInputError ? <Text type="danger">{t("enterMessageError")}</Text> : null}
                    </Row>
                  </Card>
                </Row>
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={closeTicket}
                  disabled={ticketClosed}
                >
                  {t("close")}
                </button>
              </div>
              <h4 className="page-title">{t("ticketDetail")}</h4>
              <h4>{ticketDetail.title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <SimpleBar style={{ height: 500 }}>
                  <ul className="conversation-list px-3">
                    {ticketChatContent}
                  </ul>
                </SimpleBar>
                <div className="row px-3 pb-3">
                  <div className="col">
                    <div className="mt-2 bg-light p-3 rounded">
                      <div className="row">
                        <div className="col mb-2 mb-sm-0">
                          <input
                            type="text"
                            className={
                              "form-control border-0" +
                              (replyInputError ? " is-invalid" : "")
                            }
                            placeholder={
                              ticketClosed
                                ? t("ticketClosed")
                                : t("enterMessagePlaceholder")
                            }
                            value={replyContent}
                            onInput={replyContentChange}
                            onKeyDown={replyInputKeydown}
                            disabled={ticketClosed}
                          />
                          <div className="invalid-feedback">
                            {t("enterMessageError")}
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-success chat-send"
                              onClick={submitReply}
                              disabled={ticketClosed}
                            >
                              {t("reply")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
