import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import {
  Card as SemiCard,
  Row as SemiRow,
  Col as SemiCol,
  Form as SemiForm,
  Space,
  Skeleton,
  Table as SemiTable,
  Typography,
} from "@douyinfe/semi-ui";

import { myTitle, text, MyButton } from "../common";

export default function UserSettings() {
  const { t } = useTranslation("translation", { keyPrefix: "userSettings" });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitChangePassword = () => {
    setShowSuccess(false);
    setPasswordError(false);
    setConfirmPasswordError(false);

    if (!password) {
      setPasswordError(true);
      return;
    } else if (confirmPassword != password) {
      setConfirmPasswordError(true);
      return;
    }

    axios({
      url: process.env.REACT_APP_API_ROUTE + "/account/edit/password",
      method: "POST",
      data: {
        user: {
          password: password,
          password_confirmation: confirmPassword,
        },
      },
    }).then((r) => {
      setPassword("");
      setConfirmPassword("");
      setShowSuccess(true);
    });
  };

  const submit = () => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/account/edit/password",
      method: "POST",
      data: {
        user: {
          password: password,
          password_confirmation: confirmPassword,
        },
      },
    })
      .then((r) => {
        setPassword("");
        setConfirmPassword("");
        setShowSuccess(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const passwordChange = (e) => {
    setShowSuccess(false);
    setPassword(e.target.value);
  };
  const confirmPasswordChange = (e) => {
    setShowSuccess(false);
    setConfirmPassword(e.target.value);
  };
  const submitButtonClick = () => {
    submitChangePassword();
  };
  const validate = (values) => {
    let err = {};
    if (values.password != values.comfirmPassword) {
      err.comfirmPassword = t("confirmPasswordError");
    }
    return err;
  };

  return (
    <>
      <SemiRow type="flex" justify="center">
        <SemiCol span={23}>
          <SemiRow style={{ marginTop: "25px" }} type="flex" justify="space-between">
            {myTitle(5, 5, t("userSettings"))}
          </SemiRow>
          <SemiRow style={{ marginTop: "25px" }}>
            <SemiCard>
              {myTitle(5, 6, t("passwords"))}
              <SemiForm validateFields={validate} onSubmit={submit}>
                <SemiForm.Input
                  field="password"
                  mode="password"
                  label={t("password")}
                  onChange={(v) => {
                    setPassword(v);
                  }}
                  aria-labelledby="b2cn-setting-password"
                ></SemiForm.Input>
                <SemiForm.Input
                  field="comfirmPassword"
                  mode="password"
                  label={t("confirmPassword")}
                  onChange={(v) => {
                    setConfirmPassword(v);
                  }}
                  aria-labelledby="b2cn-setting-comfirm-password"
                ></SemiForm.Input>
                <MyButton btnColor="#727cf5" htmlType="submit">
                  {t("submit")}
                </MyButton>
              </SemiForm>
            </SemiCard>
          </SemiRow>
        </SemiCol>
      </SemiRow>
      {/* <Container fluid className="user-settings-page">
        <Row>
          <Col sm={12}>
            <div className="page-title-box">
              <h4 className="page-title">{t("userSettings")}</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <h5>{t("passwords")}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="password">{t("password")}</Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        className="password-input"
                        onInput={passwordChange}
                        isInvalid={passwordError}
                        value={password}
                      />
                      <Form.Control.Feedback type="invalid">{t("passwordError")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12}>
                    <Form.Group hasValidation>
                      <Form.Label htmlFor="comfirm-password">{t("confirmPassword")}</Form.Label>
                      <Form.Control
                        type="password"
                        id="comfirm-password"
                        className="password-input"
                        onInput={confirmPasswordChange}
                        isInvalid={confirmPasswordError}
                        value={confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">{t("confirmPasswordError")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12}>
                    <Button variant="primary" onClick={submitButtonClick}>
                      {t("submit")}
                    </Button>
                  </Col>
                </Row>
                {showSuccess ? (
                  <Row className="mt-2">
                    <Col sm={12}>
                      <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        <p style={{ margin: 0 }}>{t("passwordUpdated")}</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}
