export default class UserUtil {
    static isLogin() {
        let token = localStorage.getItem("auth_token");
        return Boolean(token);
    }

    static setToken(token) {
        localStorage.setItem("auth_token", token);
    }

    static getToken() {
        return localStorage.getItem("auth_token");
    }

    static clearToken() {
        localStorage.removeItem("auth_token");
    }
}