import { Button, Typography } from "@douyinfe/semi-ui";
const { Text, Title } = Typography;

export const text = (gray, fontSize, text) => {
  return (
    <Text
      style={{ color: `rgba(var(--semi-grey-${gray}), 1)`, fontSize: fontSize }}
    >
      {text}
    </Text>
  );
};

export const myTitle = (gray, headSize, text) => {
  return (
    <Title
      heading={headSize}
      style={{ color: `rgba(var(--semi-grey-${gray}), 1)` }}
    >
      {text}
    </Title>
  );
};

export const MyButton = ({ children, ...delegated }) => {
  let style = Reflect.has(delegated, "style") ? delegated["style"] : {};
  const btnColor = Reflect.has(delegated, "btnColor")
    ? delegated["btnColor"]
    : "";
  const onClick = Reflect.has(delegated, "onClick")
    ? delegated["onClick"]
    : null;
  const btnSize = Reflect.has(delegated, "btnSize")
    ? delegated["btnSize"]
    : "default";
  const htmlType = Reflect.has(delegated, "htmlType")
    ? delegated["htmlType"]
    : "button";
  const disabled = Reflect.has(delegated, "disabled")
    ? delegated["disabled"]
    : false;
  const block = Reflect.has(delegated, "block") ? delegated["block"] : false;

  Reflect.set(style, "backgroundColor", btnColor);
  Reflect.set(style, "boxShadow", "0px 0px 10px -3px " + btnColor);
  return (
    <Button
      block={block}
      theme="solid"
      size={btnSize}
      disabled={disabled}
      htmlType={htmlType}
      //   type="tertiary"
      style={style}
      onMouseEnter={(e) => {
        const btn = e.target;
        btn.style.filter = "brightness(90%)";
        // btn.style.backgroundBlendMode = "darken";
      }}
      onMouseLeave={(e) => {
        const btn = e.target;
        btn.style.filter = "brightness(100%)";
        // btn.style.backgroundBlendMode = "normal";
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
