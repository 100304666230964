import axios from "axios";
import React from "react";
// import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Row, Skeleton, Col, Card, Table, Form, Space, Breadcrumb } from "@douyinfe/semi-ui";

import { text, myTitle, MyButton } from "../common";

export default function TicketCreate() {
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "ticketCreate" });

  const submitTicket = (v) => {
    axios({
      url: process.env.REACT_APP_API_ROUTE + "/tickets/create",
      method: "POST",
      data: {
        title: v.title,
        content: v.content,
      },
    }).then((r) => {
      navigate("/ticket");
    });
  };

  const titleChange = (e) => {
    setTitle(e.target.value);
  };
  const contentChange = (e) => {
    setContent(e.target.value);
  };

  return (
    <>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col span={23}>
            <Row style={{ marginTop: "25px" }} type="flex">
              {myTitle(5, 5, t("newTicket"))}
              <Breadcrumb style={{ marginLeft: "15px" }}>
                <Breadcrumb.Item
                  onClick={() => {
                    navigate("/ticket");
                  }}
                >
                  {t("tickets")}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("newTicket")}</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row style={{ marginTop: "25px" }}>
              <Card>
                <Form onSubmit={submitTicket}>
                  <Space spacing={15} vertical style={{ width: "100%" }}>
                    <Row style={{ width: "100%" }}>
                      <Form.Input aria-labelledby="ticket-create-title" field="title" label={text(5, 15, t("title"))} />
                    </Row>
                    <Row style={{ width: "100%" }}>
                      <Form.TextArea
                        aria-labelledby="ticket-create-content"
                        autosize
                        rows={5}
                        field="content"
                        label={text(5, 15, t("content"))}
                      />
                    </Row>

                    <Row>
                      <MyButton btnColor="#727cf5" htmlType="submit">
                        {t("submit")}
                      </MyButton>
                    </Row>
                  </Space>
                </Form>
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">{t("newTicket")}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <label htmlFor="ticket-title" className="form-label">
                    {t("title")}
                  </label>
                  <input
                    type="text"
                    id="ticket-title"
                    className="form-control"
                    onInput={titleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="ticket-content" className="form-label">
                    {t("content")}
                  </label>
                  <textarea
                    className="form-control"
                    id="ticket-content"
                    rows="5"
                    onInput={contentChange}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={submitTicket}
                  >
                    {t("submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
