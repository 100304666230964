import axios from "axios";
import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DateTime } from "luxon";
import { useTranslation } from 'react-i18next';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function PlanPurchaseSuccess() {
    const {t} = useTranslation('translation', { keyPrefix: 'planPurchaseSuccess' });

    return (
        <Container fluid className="purchase-page">
            <Row>
            <Col sm={12}>
                <div className="page-title-box">
                    <h4 className="page-title">{t("purchasePlan")}</h4>
                </div>
            </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                <h3>{t("purchaseSuccessful")}</h3>
                                <p><Link to="/order">{t("viewOrder")}</Link></p>
                            </Col>
                        </Row>
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}