import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmail(props) {
    const {email} = props;
    const {t} = useTranslation('translation', { keyPrefix: 'confirmEmail' });

    if (!email) {
        return (<Container fluid></Container>);
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xxl={4} lg={5}>
                        <Card>
                            <Card.Header className="pt-4 pb-4 text-center bg-primary"></Card.Header>
                            <Card.Body>
                                <div className="text-center m-auto">
                                    <h4 className="text-dark-50 text-center mt-4 fw-bold">{t('checkEmail')}</h4>
                                    <p className="text-muted mb-4" dangerouslySetInnerHTML={{__html: t('emailSent', {email: email})}}></p>
                                </div>
                                <div class="mb-0 text-center">
                                    <Link to="/"><Button variant="primary"><i class="mdi mdi-home me-1"></i> {t('backToLogin')}</Button></Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}