import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import UserUtil from "./util/UserUtil";
import axios from "axios";

import { BrowserRouter } from "react-router-dom";

import "./i18n";

if (UserUtil.isLogin()) {
  axios.defaults.headers.common["Authorization"] = JSON.parse(
    UserUtil.getToken()
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Set attribute to body
document
  .getElementsByTagName("body")[0]
  .setAttribute("data-leftbar-theme", "dark");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
